import axios, { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/react-query';

export type IGuestAuthRequest = {
  fingerPrint: string;
};

export function useGuestAuth() {
  return useMutation<AxiosResponse, AxiosError, IGuestAuthRequest>({
    mutationFn: (params) => axios.post('/user-service/auth/guest', undefined, { params }),
  });
}
